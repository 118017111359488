import React from 'react'
import {navigate} from 'gatsby'
import styled from '@emotion/styled'

// Components
import Layout from '../components/layout'
import SEO from '../components/organisms/seo'
import Paragraph1 from '../components/atoms/paragraph-1';
import Header1 from '../components/atoms/header-1';
import ImgThankYouBackground from '../components/images/_thank-you/img-thank-you-background';


// Styles
const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`
const Background = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
`
const ContainerText = styled.div`
  padding: 30px 5px;
  width: 100vw;
  background: rgba(255,255,255,0.8);
  text-align: center;
`

class NotFoundPage extends React.Component {
  componentDidMount() {
    this.mounted = true
    setTimeout(() => {
      if(this.mounted) {
        if(typeof window !== 'undefined') {
          navigate('/')
        }
      }
    }, 5000) 
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    return (
      <Layout>
        <SEO title="Page Not Found" />
        <Container>
          <Background>
            <ImgThankYouBackground/>
          </Background>
          <ContainerText>
            <Header1>404, Page Not Found</Header1>
            <Paragraph1 color='#000000'>Sorry, you have reached a page that doesn't exist! We will redirect you momentarily...</Paragraph1>
          </ContainerText>
        </Container>
      </Layout>
      )
    }

}

export default NotFoundPage